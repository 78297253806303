import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore } from 'redux';
import rootReducer from './combineReducers';

const createStore = () => reduxCreateStore(rootReducer);
export const store = createStore();
// const createStore = () => reduxCreateStore(rootReducer, __REDUX_DEVTOOLS_EXTENSION__ && __REDUX_DEVTOOLS_EXTENSION__());

export default ({ element }) => (
  <Provider store={store}>{element}</Provider>
);
