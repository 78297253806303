// INIT STATE
const initialState = {
  menuOpen: false,
  browser: '',
  counts: {},
  firebaseStartup: false,
  firebaseCounts: false,
  firebaseUid: false,
  realtimeKey: false,
  user: null,
};

// REDUCER
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case 'SET_BROWSER':
      return { 
        ...state, 
        browser: data
      };
    case `HANDLE_MENU_OPEN`:
      return {
        ...state, 
        menuOpen: data,
      };
    case `SET_FIREBASE_STARTUP`:
      return {
        ...state, 
        firebaseStartup: data,
      };
    case `SET_FIREBASE_COUNTS`:
      return {
        ...state, 
        firebaseCounts: data,
      };
    case `SET_FIREBASE_UID`:
      return {
        ...state, 
        firebaseUid: data,
      };
    case `SET_REALTIME_KEY`:
      return {
        ...state, 
        realtimeKey: data,
      };
    case `SET_USER_OBJECT`:
      return {
        ...state, 
        user: data,
      };
    default:
      return state;
  }
};
