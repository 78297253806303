// INIT STATE
const initialState = {
  callbackPrompt: false,
  chatrowData: {},
  chatRows: [],
  chatrowsId: '',
  docId: '',
  favico: null,
  favicoCount: 0,
  roomData: {},
  roomId: '',
  roomStep: 'lobby',
  subscriptions: [],
  userID: '',
  userIsWaiting: false,
  userName: '',
  userType: '',
};

// REDUCER
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case 'SET_INITIAL_STATE':
      return { 
        ...initialState
      };
    case 'SET_USER_TYPE':
      return { 
        ...state, 
        userType: data
      };
    case 'SET_ROOM_STEP':
      return { 
        ...state, 
        roomStep: data
      };
    case 'SET_ROOM_ID':
      return { 
        ...state, 
        roomId: data
      };
    case 'SET_USER_WAITING':
      return { 
        ...state, 
        userIsWaiting: data
      };
    case 'SET_FAVICO':
      return { 
        ...state, 
        favico: data
      };
    case 'SET_FAVICO_COUNT':
      return { 
        ...state, 
        favicoCount: data
      };
    case 'SET_SUBSCRIPTIONS':
      return { 
        ...state, 
        subscriptions: data
      };
    case 'SET_ROOM_DATA':
      return { 
        ...state, 
        roomData: data
      };
    case 'SET_CHATROWS':
      return { 
        ...state, 
        chatRows: data
      };
    case 'SET_CHATROW_DATA':
      return { 
        ...state, 
        chatRowData: data
      };
    default:
      return state;
  }
};
